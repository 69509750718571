import { Route, Routes } from "react-router-dom";
import { Home, Login, Register } from "./pages";
import { Box } from "@mui/material";
import { MainLayout } from "./layout/MainLayout";

export const App = () => {
  return (
    <Box>
      <Routes>
        <Route element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
        </Route>
        <Route path="register" element={<Register />} />
        <Route path="login" element={<Login />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </Box>
  );
};
